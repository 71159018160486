import React from "react"
import styled from "styled-components"
import mail from "../images/icons/mail.png"
import whatsapp from "../images/icons/whatsapp.png"
import content from "../content.json"
import { colors } from "../colors"

const Section = styled.section`
  max-width: 280px;
  margin: 24px auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    max-width: 380px;
  }

  @media (min-width: 768px) {
    max-width: 930px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
    margin-top: 70px;

    & > div {
      width: 45%;
    }
  }
`

const Link = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;

  li {
    color: ${colors.button};
  }
`

const Line = styled.hr`
  border-top: 1px solid ${colors.button};
`

const Address = () => (
    <Section>
      <div>
        <h3>Apúntate o solicita información aquí</h3>
        <Line />
        <Link href={`https://wa.me/${content.socialMedia.whatsapp}`} target="_blank">
          <span>{content.socialMedia.whatsapp}</span> 
          <img src={whatsapp} alt="whatsapp" />
        </Link>
        <Line />

        <Link href={`mailto:${content.socialMedia.email}?subject=Contacto Web`}>
          {content.socialMedia.email} <img src={mail} alt="email" />
        </Link>

      </div>
    </Section>
  )

export default Address;