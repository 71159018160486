import { graphql } from "gatsby"
import React from "react"
import Address from "../components/Address"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"
import TalleresSlider from "../components/TalleresSlider"
import TallerPost from "../components/TallerPost"

export default function TalleresTemplate({ data }) {
  const {
    mdx: {
      frontmatter: { title, description, tagline, cover, seo },
      body,
    },
  } = data

  return (
    <Layout>
      <Seo title={title} description={description} meta={seo} />
      <TallerPost title={title} tagline={tagline} cover={cover} body={body} />
      <Address />
      <TalleresSlider />
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        tagline
        cover {
          childImageSharp {
            gatsbyImageData(
              webpOptions: { quality: 80 }
              layout: FULL_WIDTH
              quality: 70
            )
          }
        }
        seo {
          content
          property
        }
      }
      body
    }
  }
`
