import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import styled from "styled-components"
import curve1 from "../images/shapes/curve1.svg"
import dot from "../images/shapes/dot.svg"
import orange from "../images/shapes/orange.svg"
import { colors } from "../colors"

const Section = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 104px;
    width: 173px;
    top: 56px;
    left: -70px;
    z-index: -1;
  }

  @media only screen and (max-width: 1100px) {
    ::before {
      left: -121px;
    }
  }

  @media only screen and (max-width: 992px) {
    ::before {
      left: -44px;
      height: 49px;
      width: 90px;
      background-size: cover;
    }
  }

  @media (min-width: 992px) {
    padding-bottom: 36px;
    position: relative;

    ::after {
      content: "";
      position: absolute;
      height: 89px;
      width: 166.4px;
      left: 26%;
      bottom: -40px;
      background: url(${curve1}) no-repeat;
      background-size: cover;
      transform: rotate(-139deg);
    }
  }
`

const Container = styled.div`
  margin: 48px auto 0;
  max-width: 1024px;
  @media (min-width: 992px) {
    display: flex;
  }
`

const MainText = styled.div`
  max-width: 280px;
  margin: 0 auto;

  h2 {
    margin-bottom: 0;
  }

  h5 {
    font-weight: normal;
    color: ${colors.button};
    margin-top: 8px;
  }

  p {
    line-height: 1.5;
  }

  @media (min-width: 600px) {
    max-width: 500px;
  }

  @media (min-width: 992px) {
    max-width: 440px;
  }

  @media (min-width: 992px) and (max-width: 1092px) {
    padding-left: 24px;
  }
`

const Image = styled.div`
  position: relative;
  margin: 96px 0;

  ::before {
    content: "";
    position: absolute;
    background: url(${orange});
    background-repeat: no-repeat;
    height: 186px;
    width: 50%;
    top: -62px;
    z-index: -1;
    left: 15%;
  }

  @media only screen and (min-width: 768px) {
    ::before {
      top: -77px;
    }
  }

  @media only screen and (min-width: 992px) {
    width: 50%;
    text-align: right;
    position: relative;
    padding-top: 35px;
    margin: 0;

    ::before {
      height: 220px;
      width: 71%;
      top: -38px;
      z-index: -1;
      left: 80px;
      transform: translateX(-50px);
    }

    .gatsby-image-wrapper {
      width: 322px;
      height: 522px;
      border-radius: 18px;
      display: inline-block;
    }
  }
`

const Dots = styled.div`
  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 52.31px;
    width: 97.72px;
    bottom: -60px;
    z-index: -1;
    right: 37%;
    background-size: cover;
  }
  ::after {
    content: "";
    position: absolute;
    background: url(${dot});
    background-repeat: no-repeat;
    height: 43px;
    width: 37px;
    bottom: -16px;
    z-index: -1;
    background-size: cover;
    right: 44%;
  }

  @media (min-width: 992px) {
    transform: translateX(-75px);
    ::before {
      height: 96px;
      width: 167px;
      bottom: 120px;
      right: -113px;
      background-size: unset;
    }
    ::after {
      height: 54px;
      width: 50px;
      bottom: 199px;
      right: -58px;
      background-size: unset;
    }
  }
`
export default function TallerPost({ title, tagline, cover, body }) {
  return (
    <Section>
      <Container>
        <MainText>
          <h2>{title}</h2>
          {tagline && <h5>- {tagline} -</h5>}

          <MDXRenderer>{body}</MDXRenderer>
        </MainText>
        <Image>
          <Dots>
            <GatsbyImage
              image={getImage(cover)}
              alt="person"
              placeholder="blurred"
            />
          </Dots>
        </Image>
      </Container>
    </Section>
  )
}
